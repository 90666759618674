import React from 'react';

const ModernCard = ({ icon, title, description }) => {
    return (
        <div data-aos="flip-left" data-aos-duration={Math.floor(Math.random() * (1301)) + 200} className="modern-card services-holder--cards__card">
            <div className="modern-card-icon">{icon}</div>
            <h3 className="modern-card-title">{title}</h3>
            <p className="modern-card-description">{description}</p>
        </div>
    );
};

export default ModernCard;
