import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const images = [
    "https://avosoft.de/avocloud/index.php/s/MRtKZznKfZYffDC/preview",
    "https://avosoft.de/avocloud/index.php/s/LTp9Cx9MRjaobco/preview",
    "https://avosoft.de/avocloud/index.php/s/HrRW6berzig7jeL/preview",
];

const ImageSlider = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: false,
        fade: true,
        adaptiveHeight: true // diese Option sorgt dafür, dass der Slider die volle Höhe auf mobilen Geräten einnimmt
    };

    return (
        <div style={{ position: "relative", overflow: "hidden" }}>
            <Slider {...settings}>
                {images.map((img, index) => (
                    <div key={index}>
                        <img src={img} alt={`slide-${index}`} style={{ height: "100vh", width: "100%", objectFit: "cover" }} />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default ImageSlider;
