import React, { useState, useEffect } from "react";
import Logo from "../assets/images/logo.png";
import Hamburger from "hamburger-react";

const Navbar = (props) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 992) {
                setSidebarOpen(false);
            }
        };

        const handleClickOutside = (e) => {
            if (
                !e.target.closest(".sidebar") &&
                !e.target.closest(".navbar-toggler")
            ) {
                closeSidebar();
            }
        };

        window.addEventListener("resize", handleResize);
        window.addEventListener("click", handleClickOutside);
        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const closeSidebar = () => {
        setSidebarOpen(false);
    };

    return (
        <div className="Navbar" style={{ position: "absolute", top: 0, width: "100vw", left: 0, right: 0, boxSizing: "border-box" }}>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <a className="navbar-logo" href="#">
                    <img
                        style={{ minWidth: "65px", minHeight: "65px", marginTop: "-9px" }}
                        src={Logo}
                        alt="Logo"
                    />
                </a>
                <div className="collapse navbar-collapse justify-content-center">
                    <div className="navbar-nav">
                        <a className="nav-item nav-link navbar-font navbar-defaultitem" onClick={() => props.onSelectNavbarItem("scroll_home")}>
                            STARTSEITE
                        </a>
                        <a className="nav-item nav-link navbar-font navbar-defaultitem" onClick={() => props.onSelectNavbarItem("scroll_dienstleistungen")}>
                            DIENSTLEISTUNGEN
                        </a>
                        <a className="nav-item nav-link navbar-font navbar-defaultitem" onClick={() => props.onSelectNavbarItem("scroll_kat1")}>
                            KATEGORIEPUNKT 1
                        </a>
                        <a className="nav-item nav-link navbar-font navbar-defaultitem"  onClick={() => props.onSelectNavbarItem("scroll_kat2")}>
                            KATEGORIEPUNKT 2
                        </a>
                        <a className="nav-item nav-link navbar-font navbar-defaultitem"  onClick={() => props.onSelectNavbarItem("scroll_kontakt")}>
                            KONTAKT
                        </a>
                    </div>
                </div>

                <button
                    className={`navbar-toggler${sidebarOpen ? " active" : ""}`}
                    type="button"
                    onClick={toggleSidebar}
                    style={{ border: "none", background: "none", outline: "none", color: "white" }}
                >
                    <Hamburger toggled={sidebarOpen} toggle={toggleSidebar} />
                </button>

                <div className={`sidebar-wrapper${sidebarOpen ? " sidebar-open" : ""}`}>
                    <div className="sidebar">
                        <div className="sidebar-header">
                            <h3 className="navbar-font" style={{fontWeight: "lighter", fontSize: "20px", letterSpacing: "1.2px"}}>Kategorien</h3>
                            <button className="close-sidebar-btn" onClick={closeSidebar}>
                                <i className="fas fa-times"></i>
                            </button>
                        </div>
                        <ul className="sidebar-menu">
                            <li className="sidebar-item">
                                <a className="sidebar-link" onClick={toggleSidebar}>
                                    <div className="row" style={{width: "100%"}}>
                                        <div className="col-3 d-flex align-items-center justify-content-center">
                                            <i className="fas fa-home sidebar-icon"></i>
                                        </div>
                                        <div className="col-9 d-flex align-items-center">
                                            <span className="sidebar-text" onClick={() => props.onSelectNavbarItem("scroll_home")}>Startseite</span>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li className="sidebar-item">
                                <a className="sidebar-link" onClick={toggleSidebar}>
                                    <div className="row" style={{width: "100%"}}>
                                        <div className="col-3 d-flex align-items-center justify-content-center">
                                            <i className="fas fa-briefcase sidebar-icon"></i>
                                        </div>
                                        <div className="col-9 d-flex align-items-center" onClick={() => props.onSelectNavbarItem("scroll_dienstleistungen")}>
                                            <span className="sidebar-text">Dienstleistungen</span>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li className="sidebar-item">
                                <a className="sidebar-link" onClick={toggleSidebar}>
                                    <div className="row" style={{width: "100%"}}>
                                        <div className="col-3 d-flex align-items-center justify-content-center">
                                            <i className="fas fa-star sidebar-icon"></i>
                                        </div>
                                        <div className="col-9 d-flex align-items-center" onClick={() => props.onSelectNavbarItem("scroll_kat1")}>
                                            <span className="sidebar-text">Kategoriepunkt 1</span>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li className="sidebar-item">
                                <a className="sidebar-link" onClick={toggleSidebar}>
                                    <div className="row" style={{width: "100%"}}>
                                        <div className="col-3 d-flex align-items-center justify-content-center">
                                            <i className="fas fa-star sidebar-icon" ></i>
                                        </div>
                                        <div className="col-9 d-flex align-items-center" onClick={() => props.onSelectNavbarItem("scroll_kat2")}>
                                            <span className="sidebar-text">Kategoriepunkt 2</span>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li className="sidebar-item">
                                <a className="sidebar-link" onClick={toggleSidebar}>
                                    <div className="row" style={{width: "100%"}}>
                                        <div className="col-3 d-flex align-items-center justify-content-center">
                                            <i className="fas fa-address-book sidebar-icon"></i>
                                        </div>
                                        <div className="col-9 d-flex align-items-center" onClick={() => props.onSelectNavbarItem("scroll_kontakt")}>
                                            <span className="sidebar-text">Kontakt</span>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Navbar;
