import Background from "./Header/Background";
import Navbar from "../global/Navbar";
import Carousel from "./Header/Carousel";
import ScrollDown from "./Header/ScrollDown";
import Services from "./Services/Services";
import Explanation from "./Exlanation/Explanation";
import Footer from "../global/Footer";
import Contact from "../global/Contact";
import ImageCard from "../global/ImageCard";
import React, {useEffect, useRef} from 'react';
import AOS from 'aos';

import Image from "../assets/images/20512813-2.jpg";

const LandingPage = () => {

    useEffect(() => {
        AOS.init({
            duration : 2000
        });
    }, []);

    const scroll_Home_Ref = useRef(null);
    const scroll_Dienstleistungen_Ref = useRef(null);
    const scroll_Kat1_Ref = useRef(null);
    const scroll_Kat2_Ref = useRef(null);
    const scroll_Kontakt_Ref = useRef(null);

    const customScrollTo = (targetY, duration) => {
        const smoothScroll = (targetY, duration) => {
            const start = performance.now();

            const startY = window.pageYOffset;
            const diff = targetY - startY - 80;

            const easeInOutCubic = (t) => {
                return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
            };

            const animateScroll = (currentTime) => {
                const elapsed = currentTime - start;
                const progress = Math.min(elapsed / duration, 1);
                const easedProgress = easeInOutCubic(progress);

                window.scrollTo(0, startY + diff * easedProgress);

                if (elapsed < duration) {
                    requestAnimationFrame(animateScroll);
                }
            };

            requestAnimationFrame(animateScroll);
        };

        if ('scrollBehavior' in document.documentElement.style) {
            window.scrollTo({ top: targetY - 80, behavior: 'smooth' });
        } else {
            smoothScroll(targetY, duration);
        }
    };


    const scrollToTarget = (item) => {
        if (item.current) {
            const targetY = item.current.offsetTop;
            const duration = 1000; // Animationdauer in Millisekunden, hier 1000 ms
            customScrollTo(targetY, duration);
        }
    };

    function onSelectNavbarItem(name) {

        let item = null;

        switch (name) {
            case "scroll_home":
                item = scroll_Home_Ref;
                break;
            case "scroll_dienstleistungen":
                item = scroll_Dienstleistungen_Ref;
                break;
            case "scroll_kat1":
                item = scroll_Kat1_Ref;
                break;
            case "scroll_kat2":
                item = scroll_Kat2_Ref;
                break;
            case "scroll_kontakt":
                console.log("Giving")
                item = scroll_Kontakt_Ref;
                break;
        }

        scrollToTarget(item)
    }

    return (
        <div className="landing-page">
            <div ref={scroll_Home_Ref}></div>
            <Navbar onSelectNavbarItem={(item) => onSelectNavbarItem(item)} />
            <Carousel />
            <ScrollDown />
            <div ref={scroll_Dienstleistungen_Ref}>
                <Services />
            </div>
            <Explanation />
            <div>
                <div ref={scroll_Kat1_Ref}>
                <ImageCard
                    image={Image}
                    header={"Überschrift"}
                    text={
                        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."
                    }
                />
                </div>
                <div ref={scroll_Kat2_Ref}>
                    <ImageCard
                        image={Image}
                        header={"Überschrift"}
                        text={
                            "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."
                        }
                        left
                    />
                </div>
            </div>
            <div className="container" ref={scroll_Kontakt_Ref}>
                <Contact />
            </div>
            <Footer />
        </div>
    );
};

export default LandingPage;
