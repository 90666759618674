import React from 'react';

import ScrollDownIcon from '../../assets/images/scrolldown.svg';

export const ScrollDown = (props) => {
    return (
        <>
            <div className="position-absolute top-50 start-50 translate-middle rounded" style={{transform: "translate(-50%, -50%)", textAlign: "center", background: "radial-gradient(ellipse at center, rgba(0,0,0,0.7) 0%,rgba(0,0,0,0.4) 100%)", padding: "20px", marginLeft: 0}}>
                <h3 className="navbar-font header-text" style={{color: "white"}}>Zeichenbüro Falk</h3>
                <span className="default-span-font header-text__description" style={{color: "white"}}>Wir unterstützen Sie mit unserer Expertise</span>
            </div>





            <div className="scrolldown">


                <img src={ScrollDownIcon} width="64px" onClick={() => window.scroll({
                    top: 600,
                    left: 0,
                    behavior: 'smooth',
                    alt: "Scrolldown"
                })}/>

        </div>
            </>
    )
};

export default ScrollDown;
