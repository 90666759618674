import React from "react";
import {createRoot} from "react-dom/client";
import LandingPage from "./LandingPage/LandingPage";


const container = document.getElementById('root')
const root = createRoot(container);


root.render(<LandingPage/>);
