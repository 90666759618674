import React from "react";

export default class Footer extends React.Component {


    render() {
        return (
            <>
                <footer className="footer-distributed">

                    <div className="footer-left">

                        <h3>Zeichenbüro Falk
                            <span>
                                <img className="footer__logo" src="" alt="" />
                            </span>
                        </h3>


                        <p className="footer-company-name">&copy; Avosoft GbR 2023</p>
                    </div>

                    <div className="footer-center">

                        <div>
                            <i className="fa fa-map-marker"></i>
                            <p><span>Zeisigweg 29</span> 33415, Verl</p>
                        </div>

                        <div>
                            <i className="fa fa-phone"></i>
                            <p>+49 1234 54356544</p>
                        </div>

                        <div>
                            <i className="fa fa-envelope"></i>
                            <p><a href="mailto:support@avosoft.de">support@avosoft.de</a></p>
                        </div>

                    </div>

                    <div className="footer-right">

                        <p className="footer-company-about">
                            <span>Über uns</span>
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
                        </p>


                    </div>

                </footer>
            </>
        );
    }

}
