import React from 'react';

class Explanation extends React.Component {

    render () {
        return (
            <div>
                <div className="explanation-mein-bild">
                    <div className="svg-container">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                        <path
                            fill="#20b6cd"
                            fillOpacity="1"
                            d="M0,224L30,202.7C60,181,120,139,180,133.3C240,128,300,160,360,170.7C420,181,480,171,540,165.3C600,160,660,160,720,160C780,160,840,160,900,176C960,192,1020,224,1080,234.7C1140,245,1200,235,1260,240C1320,245,1380,267,1410,277.3L1440,288L1440,0L1410,0C1380,0,1320,0,1260,0C1200,0,1140,0,1080,0C1020,0,960,0,900,0C840,0,780,0,720,0C660,0,600,0,540,0C480,0,420,0,360,0C300,0,240,0,180,0C120,0,60,0,30,0L0,0Z"
                        ></path>
                    </svg>
                    </div>
                </div>

            </div>
        )
    }

}

export default Explanation;
