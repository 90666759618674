import React from "react";

export default class ImageCard extends React.Component {

    handleRow() {
        if (this.props.left) {
            return(
                <>
                    <div className="col-lg-6 col-md-12 text-center">
                        <img data-aos="fade-left" src={this.props.image} alt={"image"} width="100%" height="auto" />
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <h1 className="imagecard--text">{this.props.header}</h1>
                        <p className="mt-4">{this.props.text}</p>
                    </div>
                </>
            )
        }

        return(
            <>
                <div className="col-lg-6 col-md-12">
                    <h1 className="imagecard--text">{this.props.header}</h1>
                    <p className="mt-4">{this.props.text}</p>
                </div>
                <div className="col-lg-6 col-md-12 text-center">
                    <img data-aos="fade-right" src={this.props.image} alt={"image"} width="100%" height="auto" />
                </div>
            </>
        )
    }

    render() {
        return(
            <div className="container-fluid mt-5 row">
                {this.handleRow()}
            </div>
        )
    }

}
