import React from "react";

export default class Contact extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            counter: 90,
            username: "",
            body: "",
            email: "",
        }

        this.handleCounter = this.handleCounter.bind(this);
        this.handleForm = this.handleForm.bind(this);
    }

    handleCounter() {

    }

    handleForm(event) {
        const {name, value} = event.target;

        this.setState({[name]: value});
    }

    render() {
        return (
                <div className="col-lg-10 col-sm-12 m-auto mt-5 mb-5 contact-container">
                    <span className="line-top"></span>
                    <form id="form">
                        <h1>Kontakt</h1>
                        <div className="input">
                            <i className="fas fa-user"></i>
                            <input type="text" name="username" onChange={this.handleForm} required="" placeholder="Vor- und Nachname" />
                        </div>
                        <div className="input">
                            <i className="fas fa-at"></i>
                            <input type="mail" name="email" onChange={this.handleForm} required="" placeholder="ihre@email.com" />
                        </div>
                        <div className="input">
                            <i className="far fa-comment-alt"></i>
                            <textarea id="subject" name="body" onChange={this.handleForm} placeholder="Schreiben Sie uns eine Nachricht." onKeyUp={this.handleCounter}
                                       ></textarea>
                        </div>
                    </form>
                    <a className="contact-button" href={"mailto:info@avosoft.de" +
                    "?Subject=Kontaktformular&body=Hallo%20Zeichbüro-Team,%0D%0A" + this.state.body + "%0D%0A%0D%0AMit freundlichen Grüßen %0D%0A" + this.state.username}
                       style={{ textDecoration: 'none', border: 'none', cursor: 'pointer' }}
                    >
                        <i className="fas fa-paper-plane"></i>
                    </a>
                </div>
        );
    }

}
