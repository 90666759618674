import React from 'react';
import ModernCard from "./ModernCard";
import { FaRocket } from 'react-icons/fa';

class Services extends React.Component {

    render () {
        return(
            <>
            <div style={{background: "#20b6cd", minHeight: "600px"}}>
                <div className="services-holder">
                    <h3 className="services-holder--text navbar-font" style={{color: "white"}}>DIENSTLEISTUNGEN</h3>
                    <span className="default-span-font services-holder--description" style={{color: "white"}}>Folgende Dienstleistungen können wir für Sie realisieren</span>
                </div>
                <div className="services-holder--cards">
                    <ModernCard
                        icon={<FaRocket />}
                        title="Moderne Card"
                        description="Dies ist eine moderne Card mit einem Icon, einer Überschrift und einer Beschreibung. Sie verfügt über Hover-Effekte und einen Effekt an den Seiten der Card."
                    />
                    <ModernCard
                        icon={<FaRocket />}
                        title="Moderne Card"
                        description="Dies ist eine moderne Card mit einem Icon, einer Überschrift und einer Beschreibung. Sie verfügt über Hover-Effekte und einen Effekt an den Seiten der Card."
                    />
                    <ModernCard
                        icon={<FaRocket />}
                        title="Moderne Card"
                        description="Dies ist eine moderne Card mit einem Icon, einer Überschrift und einer Beschreibung. Sie verfügt über Hover-Effekte und einen Effekt an den Seiten der Card."
                    />
                    <ModernCard
                        icon={<FaRocket />}
                        title="Moderne Card"
                        description="Dies ist eine moderne Card mit einem Icon, einer Überschrift und einer Beschreibung. Sie verfügt über Hover-Effekte und einen Effekt an den Seiten der Card."
                    />
                    <ModernCard
                        icon={<FaRocket />}
                        title="Moderne Card"
                        description="Dies ist eine moderne Card mit einem Icon, einer Überschrift und einer Beschreibung. Sie verfügt über Hover-Effekte und einen Effekt an den Seiten der Card."
                    />
                </div>
            </div>
                </>
        )
    }

}

export default Services;
